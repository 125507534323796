export const STLCOLOR = {
  // figma 참고
  // https://www.figma.com/file/0SCsrqooDiht32mnjGLjF9/%EC%8A%A4%ED%86%A0%EC%96%B4%EB%A7%81%ED%81%AC-5.0-%EC%BB%AC%EB%9F%AC?node-id=0%3A1
  blue: '#0264FB',
  blueHoverd: '#3583FC',
  blue2: '#C4D9F9',
  blue3: '#DCE7F8',
  blue4: '#E4EFFF',
  blue5: '#ECF4FF',
  blueLight: '#E6F0FF',
  grayblueBg: '#F9FBFF',
  yellow: '#FFE600',
  yellowHovered: '#FFEB33',
  red: '#FB3E02',
  redHovered: '#FC6535',
  orange: '#FF9E48',
  green: '#2ACA6E',
  purple: '#AD56FD',
  marinPruple: '#625fff',
  black: '#000000',
  white: '#FFFFFF',
  gray1: '#333333',
  gray2: '#666666',
  gray3: '#999999',
  gray4: '#CCCCCC', // Inputboxtext
  gray5: '#F2F2F2', // Stroke
  gray6: '#FAFAFA',
  gray7: '#D9D9D9',
  gradientPurpleBlue: 'linear-gradient(270deg, #625fff 3.24%, #0264FB 102.39%)',
};

export const DHCOLOR = {
  red: '#e50012',
  red5: '#f3eff1',
};

export const ADLINKCOLOR = {
  mainblue: '#459af7',
};

export const DEVICESIZE = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  default: '1440px',
  xxl: '1600px',
};

export const MEDIA = {
  xs: `@media (max-width: ${DEVICESIZE.xs})`,
  sm: `@media (max-width: ${DEVICESIZE.sm})`,
  md: `@media (max-width: ${DEVICESIZE.md})`,
  lg: `@media (max-width: ${DEVICESIZE.lg})`,
  xl: `@media (max-width: ${DEVICESIZE.xl})`,
  default: `@media (max-width: ${DEVICESIZE.default})`,
  xxl: `@media (max-width: ${DEVICESIZE.xxl})`,
};
