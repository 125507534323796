import { css } from '@emotion/react';
import { STLCOLOR } from '../../styles/GlobalStyleVariables';

export const cssCustomToastStyle = css`
  &.Toastify__toast-container {
    //min-width: 500px;
    font-size: 14px;
    color: ${STLCOLOR.gray1};
    font-weight: 500;
    line-height: 22px;
  }
  .Toastify__toast {
    border-radius: 5px;
    //padding: 8px;

    .Toastify__toast-body {
      //padding: 6px;
      padding: 0 6px;
      font-family: Pretendard, sans-serif;
    }
  }
  &:has(.Toastify__toast--info) {
    .Toastify__toast--info {
      border: 1px solid var(--toastify-color-info);
    }
  }
  &:has(.Toastify__toast--success) {
    .Toastify__toast--success {
      border: 1px solid var(--toastify-color-success);
    }
  }
  &:has(.Toastify__toast--error) {
    .Toastify__toast--error {
      border: 1px solid var(--toastify-color-error);
    }
  }
  &:has(.Toastify__toast--warning) {
    .Toastify__toast--warning {
      border: 1px solid var(--toastify-color-warning);
    }
  }
  &:has(.Toastify__toast--default) {
    .Toastify__toast--default {
      // default
    }
  }
`;
