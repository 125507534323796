import type { FC } from 'react';
import { ToastContainer, toast, Flip } from 'react-toastify';
import type { ToastContainerProps } from 'react-toastify';
import { cssCustomToastStyle } from './CustomReactToastify.styles';

export const CustomToastContainer: FC<ToastContainerProps> = (props) => {
  return (
    <ToastContainer
      css={cssCustomToastStyle}
      position="top-center"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Flip}
      limit={5}
      {...props}
    />
  );
};

export { toast };
