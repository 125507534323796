import type { ThemeConfig } from 'antd/es/config-provider/context';

export const adlinkTheme: ThemeConfig = {
  token: {
    colorBgBase: '#ffffff',

    borderRadius: 5,

    controlHeightLG: 54,
    controlHeightSM: 28,
    // controlHeightXS

    colorPrimary: '#459af7',
    colorError: '#fb3e02',

    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)',

    fontFamily: 'Pretendard,sans-serif',
  },
  components: {
    Layout: {
      colorBgBody: 'transparent',
      colorBgHeader: 'transparent',
    },
    Modal: {
      // borderRadius: 2,
      colorPrimary: 'red',
      borderRadiusOuter: 2,
    },
    // Select: {
    //   fontSizeLG: 14,
    // }
  },
};
