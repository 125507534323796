import {
  CustomToastContainer,
  GlobalResetStyle,
} from '@storelink-io/storelink-ui';
import { adlinkTheme } from '@storelink-io/storelink-ui/styles/adlinkTheme';
import { ConfigProvider } from 'antd';
import type { FC, ReactNode } from 'react';

import {
  QueryClient,
  QueryClientProvider,
  Hydrate,
  type DehydratedState,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RecoilRoot } from 'recoil';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 60 * 60 * 1000 * 24,
      staleTime: 5000,
      retry: false,
      onSuccess: (data) => {
        // fetch success
      },
    },
    mutations: {
      onError: () => {
        // mutaion error
      },
    },
  },
});

type Props = {
  dehydratedState?: DehydratedState;
  children: ReactNode;
};

export const AppProviders: FC<Props> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={true} />
      <Hydrate state={props.dehydratedState}>
        <GlobalResetStyle />
        <RecoilRoot>
          <ConfigProvider theme={adlinkTheme}>{props.children}</ConfigProvider>
          <CustomToastContainer />
        </RecoilRoot>
      </Hydrate>
    </QueryClientProvider>
  );
};
