import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import nextI18nextConfig from '../../next-i18next.config';
import { AppProviders } from '../AppProviders';
import '@storelink-io/storelink-ui/base/toast/CustomReactToastify.css';

// Workaround for https://github.com/zeit/next.js/issues/8592
export type AppProps = NextAppProps & {
  // 오류가 있는 경우에만 적용됨.
  err?: Error;
};

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
const MpApp = (appProps: AppProps) => {
  const { Component, pageProps, err } = appProps;
  return (
    <AppProviders dehydratedState={pageProps.dehydratedState}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <Component {...pageProps} err={err} />
    </AppProviders>
  );
};

export default appWithTranslation(MpApp, {
  ...nextI18nextConfig,
});
